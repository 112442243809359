@import "colors";
@import "misc";

body {
  background: $backgroundColor;
  margin: 0;
  padding-top: calc(35px + 120px);  // .top-bar height + .navigation height
  @media (max-width: $tablet) { padding-top: calc(35px + 74.13px); }  // .top-bar height + .navigation height

  // font default settings
  color: $defaultFontColor;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.03rem;

  @media (max-width: $mobile) {
    font-size: 12px;
    // padding-top: 8rem;
  }
  a {
    text-decoration: none;
    color: $secondaryColor;
    cursor: pointer;
  }

  h1 {
    margin-top: 4rem;
    margin-bottom: 0;
    color: $primaryColor;
    display:flex;
    justify-content: center;
    font-weight: normal;
    @media (max-width: $mobile) {
      font-size: 14px;
      margin-top: 2rem;
    }
    &.text-align-start{
      justify-content: unset;
    }
  }

  .left {
    justify-content: start;
  }

  h2 {
    margin-top: 4rem;
    margin-bottom: 0;
    color: $primaryColor;
    font-weight: normal;
    @media (max-width: $mobile) {
      font-size: 16px;
      margin-top: 2rem;
    }
  }
  h3{
    margin-top: 4rem;
    margin-bottom: 0;
    color: $primaryColor;
    font-size: 2rem;
    line-height: 1.6;
    font-weight: normal;
    @media (max-width: $mobile) {
      font-size: 14px;
      margin-top: 2rem;
    }
  }

  
}

@mixin btn {
  cursor: pointer;
  font-size: 11px;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: .075rem;
  border-radius: 6px;
  transition: all .2s;
  line-height: 20px;
  font-weight: 400;
  color: white;
  padding: 12px 16px;
  min-width: 140px;
  text-align: center;
  @media (max-width: $mobile) {
    font-size: 10px;
  }

  &:hover {
    opacity: .9;;
  }
}

.btn {
  @include btn;

  background-color: $primaryColor;
}

.btn-blue {
  @include btn;

  background-color: $secondaryColor;
  padding: 9px 11px;
}

.blue {
  background-color: $secondaryColor;
}

.container {
  padding-top: 6.25em;
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: $mobile) {
    padding-top: 2em;
  }

  .wrapper {
    flex-basis: 90rem;
    padding: 0 1rem;
    width: 90%;
    margin-bottom: 8rem;

    @media (max-width: $mobile) {
      margin-bottom: 2rem;
    }

    h2 {
      display:flex;
    }
    
    img {
      margin: 3rem 0;
    }

    .icon-grid {
      margin-bottom: 0;
    }

    .job-image {
      margin: 0;
      margin-bottom: 3rem;
      margin-inline-end: 1rem;

      img { margin: 0; }

      @media (max-width: $mobile) {
        margin-bottom: 0;
      }
    }

    .job-desc {
      margin-bottom: 3rem;

      h3 {
        margin: .3rem 0;
      }
    }

    .center {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    .mobile-center {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: $mobile) {
        justify-content: center;
      }
    }

    input {
      width: 100%;
      margin: 0;
      padding: 1rem 0;
      font-size: 1rem;
      line-height: 1.2rem;
      height: 50px;
      border: none;
      border-bottom: 1px solid #B2BFC5;
      outline: 0;
      background-color: transparent;
      box-sizing: border-box;
      font: inherit;
      margin-bottom: 2rem;
    }
    textarea {
      width: 100%;
      height: 100px;
      margin: 0;
      padding: 1rem 0;
      font-size: 1rem;
      line-height: 1.2rem;
      border: none;
      border-bottom: 1px solid #B2BFC5;
      outline: 0;
      background-color: transparent;
      box-sizing: border-box;
      font: inherit;
      margin-bottom: 2rem;
    }
    input[type=button], input[type=submit] {
      width: 20%;
      line-height: 1;
      background-color: $primaryColor;
      border: none;
      color: white;
      padding: 16px 32px;
      text-decoration: none;
      margin: 10px 2px;
      cursor: pointer;
      border-radius: 6px;
      margin-bottom: 5rem;
    }
    input[type=submit]:disabled{
      background-color: lightgray;
      color: $defaultFontColor;
    }
  }
}

.white {
  color: white;
}

.blue-text {
  color: $secondaryColor;
}

.orange {
  color: $primaryColor;
}

.small {
  width: 7rem;
  height: 7rem;
  @media (max-width: $mobile) {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0 !important;
  }
}

.blue-box{
  background-color: $secondaryColor;
  color: #ffffff;
}

.white-box{
  background-color: #ffffff;
  color: $secondaryColor;
}

.grid-container-nobreak {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-evenly;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  
  @media (max-width: $mobile) {
    grid-template-columns: auto;
  }
}

.row-container {
  display: flex;
  justify-content: center;
  @media (max-width: $mobile) {
    flex-direction: column;
    img {
      display: none;
    }
    .img-keep {
      display: block;
    }
  }
}

.tablet-row {
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.column-container {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  p {
    margin: 0.5rem 0;
  }
}

.desktop-container {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  height: auto;
  max-width: 614px;
  .desktop-content {
    padding: 3.2% 8.5% 24%;
  }
}

.section-banner {
  padding: 5rem 0;
  background-color: $secondaryColor;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  @media (max-width: $mobile) {
    flex-direction: column;
    padding: 2rem 0;
  }
}

.banner-content {
  display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: $mobile) {
    margin: 3rem 0;
  }
}

.resize-image {
  max-width: 100%;
  height: auto;
}

.resize-image-medium {
  max-width: 50%;
  height: auto;
  @media (max-width: $mobile) {
    max-width: 80%;
    height: auto;
  }
}

.resize-image-small {
  max-width: 30%;
  height: auto;
  @media (max-width: $mobile) {
    max-width: 70%;
    height: auto;
  }
}

.mobile-image {
  @media (max-width: $mobile) {
    display: none;
  }
}

.border-image {
  border: solid;
  border-color: $secondaryColor;
  border-width: 0.3rem;
  border-radius: 10px;
}

.btn-research_technology {
  background-color: $primaryColor;
  text-transform: uppercase;
  letter-spacing: .075rem;
  border-radius: 6px;
  transition: all .2s;
  line-height: 20px;
  font-weight: 400;
  color: white;
  padding: 12px 16px;
  min-width: 140px;
  text-align: center;
  max-width: 3rem; 
  margin-bottom: 3rem;
  &:hover {
    opacity: .9;;
  }
}

.research_technology_img {
  margin: 0;
  width: 216px;
  height: auto;
  @media (max-width: $mobile) {
    width: 120px;
  }
}

.research_technology_img-large {
  margin: 0;
  width: 250px;
  height: auto;
}

.resize-image-2 {
  max-width: 30rem;
  max-height: 30rem;
  width: 50%;
  height: 50%;
}

.text-on-image-container {
  position: relative;
  text-align: center;
  color: white;
  margin: 0 2rem;
}

.text-on-image-centered {
  position: absolute;
  top: 60%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}

.margin-top-bottom {
  margin: 3rem 0;
}

.margin-top-small {
  margin-top: 2rem;
}

.margin-top-bottom-large {
  margin: 5rem 0;
}

.tablet-image {
  @media (max-width: $tablet) {
    display: none;
  }
}

.video{
  width: 35rem;
  height: 25rem;
  border: solid;
  border-color: $secondaryColor;
  border-width: 0.3rem;
  border-radius: 10px;
  @media (max-width: $mobile) {
    width: 20rem;
    height: 15rem;
  }
}

.separated-row {
  margin-top: 15rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: $mobile) {
    flex-direction: column;
    margin-top: 7rem;
  }
}

.column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.no-margin {
  margin: 0;
}

.bottom-margin-small {
  margin-bottom: 2rem;
}

.margin-bottom {
  margin-bottom: 5rem;
}

.top-margin {
  margin-top: 15rem;
  @media (max-width: $mobile) {
    margin-top: 5rem;
  }
}

.icon {
  @media (max-width: $mobile) {
    max-width: 50%;
    max-height: 50%;
  }
}

.icon-small {
  @media (max-width: $mobile) {
    max-width: 25%;
    max-height: 25%;
  }
}

.icon-grid {
  @media (max-width: $mobile) {
    max-width: 7rem;
    max-height: 7rem;
  }
}

.centered-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small-header {
  font-size: 24px;
}

div {
  .center {
    align-items: center;
  }
}

#german-image{
  img{
    width: 25rem;
    height: 15rem;
    margin-inline-start: 5rem;

    @media (max-width: $mobile) {
      width: 20rem;
      margin-inline-start:0;
    }
  }
}

#german-image{
  display: flex;
  justify-content: center;
}

ul.vertical-center{
  li {
    display: flex;
    align-items: center;
  }
  picture {
    line-height: 1;
  }
}

.text-highlight{
  background-color: rgb(196, 189, 189);
  border: 1px solid black;
  padding-inline-start:0.2rem;
  padding-inline-end:0.2rem;
}